$(document).on('click', '#login-btn',loginAdmin);
$(document).on('click', '#forgot-password', restorePassword);
$(document).ready(function(){
});
function loginAdmin(){
    let flag = true;
    let container = $(this).parent();
    let identification = $('#login-identification').val();
    let password = $('#login-password').val();
    if(identification==null || identification == ""){
        container.find('#create-admin-identification').addClass('is-invalid');
        alertWarning('Debe ingresar la identificación del administrador');
        flag = false;
    }else{
        container.find('#create-admin-identification').removeClass('is-invalid');
    }
    if(password == null || password == ""){
        container.find('#create-admin-password').addClass('is-invalid');
        alertWarning('Debe ingresar la contraseña');
        flag = false;
    }
    if(flag){
        $('#login-btn').attr('disabled',true);
        let DataSend ={
            identification:identification,
            password:password
        }
        PostMethodFunction('/user/login',DataSend,null, function(response){
            window.location.href = "/user/dashboard";
        },function(){$('#login-btn').attr('disabled',false);});
    }
}
function restorePassword(){
    let flag = true;
    let container = $(this).parent();
    let identification = $('#login-identification').val();
    if(identification==null || identification == ""){
        container.find('#login-identification').addClass('is-invalid');
        alertWarning('Debe ingresar la identificación del administrador');
        flag = false;
    }else{
        container.find('#login-identification').removeClass('is-invalid');
    }
    if(flag){
        $('#forgot-password').attr('disabled',true);
        let DataSend ={
            identification:identification
        }
        PostMethodFunction('/user/restore-password',DataSend,null, function(response){
            swallMessage(
                'Contraseña restaurada'
                , 'Se ha enviado un correo con la nueva contraseña'
                , 'success'
                , 'Entendido'
                , null
                , null
                , null
                , null
            );
        },function(){$('#forgot-password').attr('disabled',false);});
    }
}